import request from '@/services/request'

export function getVoucherList(data) {
  return request({
    url: '/3tVoucher/getVoucherList',
    method: 'post',
    data: data
  })
}
export function getVoucher(voucher_num, data) {
  return request({
    url: '/3tVoucher/getVoucher/' + voucher_num,
    method: 'post',
    data: data
  })
}

export function useVoucher(voucher_num, data) {
  return request({
    url: '/3tVoucher/use/' + voucher_num,
    method: 'post',
    data: data
  })
}

// 自主核銷
export function useVoucherSelf(voucher_num, data) {
  return request({
    url: '/3tVoucher/useSelf/' + voucher_num,
    method: 'post',
    data
  })
}

// 取得 gds 歸戶 token (歸戶至次級市場)
export function getGdsVerify(voucher_num) {
  return request({
    url: '/3tVoucher/getGdsVerify/' + voucher_num,
    method: 'post'
  })
}
