<template>
  <div>
    <b-modal
      id="modal_qrcode"
      ref="modal_qrcode"
      scrollable
      title="掃描店家QRcode"
      hide-footer
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      @hide="destroyScanner()"
      @show="createScanner()"
    >
      <div class="scanner">
        <qrcode-stream
          v-if="!destroyed"
          :camera="camera"
          :track="paintOutline"
          @decode="onDecode"
          @init="onInit"
        >
          <table v-if="detect ==='loading' " class="loading-indicator">
            <tr>
              <td>
                <div class="msg">
                  裝置偵測中...
                </div>
              </td>
            </tr>
          </table>
          <table v-else-if="detect ==='fail' " class="loading-indicator">
            <tr>
              <td>
                <div class="msg">
                  {{ error }}
                </div>
                <button type="button" class="btn btn-sm btn-next" @click="reload">
                  重新偵測
                </button>
              </td>
            </tr>
          </table>
        </qrcode-stream>
      </div>
      <div class="upload camera-area mt-5">
        <p>請確認QRcode無髒污破損, 若仍無法掃瞄請嘗試拍照上傳</p>
        <label class="btn btn-next" for="qrcode-capture">
          上傳偵測
        </label>
        <qrcode-capture
          id="qrcode-capture"
          name="qrcode-capture"
          :multiple="false"
          style="display: none;"
          @decode="onDecode"
        />
        <p>或</p>
        <button type="button" class="btn btn-sm btn-next" @click="storeCode()">
          手動輸入商家代碼
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { QrcodeCapture, QrcodeStream } from 'vue-qrcode-reader'
import { showNotice } from '@/utils/tool'

export default {
  name: 'QrCodeScan',
  components: {
    QrcodeStream,
    QrcodeCapture
  },
  data() {
    return {
      result: null,
      camera: 'auto',
      detect: 'loading',
      error: null,
      refreshBtnShow: false,
      destroyed: false
    }
  },
  computed: {},
  mounted() {
    this.turnCameraOn()
    this.createScanner()
  },
  methods: {
    showModal() {
      this.$bvModal.show('modal_qrcode')
    },
    hideModal() {
      this.$bvModal.hide('modal_qrcode')
    },
    async onInit(promise) {
      this.detect = 'loading'

      try {
        await promise

        this.detect = true
      } catch (error) {
        this.detect = 'fail'

        if (error.name === 'NotAllowedError') {
          this.error = '請開啟相機權限'
          this.refreshBtnShow = true
        } else if (error.name === 'NotFoundError') {
          this.error = '此裝置查無相機裝置'
        } else if (error.name === 'NotSupportedError') {
          this.error = '請使用https安全網址'
        } else if (error.name === 'NotReadableError') {
          this.error = '相機無法存取'
          this.refreshBtnShow = true
        } else if (error.name === 'OverconstrainedError') {
          this.error = '相機無法存取'
          this.refreshBtnShow = true
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = '瀏覽器不支援此功能'
        } else if (error.name === 'InsecureContextError') {
          this.error = '請使用https安全網址'
        } else {
          this.error = `相機存取錯誤 (${error.name})`
          this.refreshBtnShow = true
        }
      }
    },
    async reload() {
      this.destroyed = true

      await this.$nextTick()

      this.destroyed = false
    },
    async onDecode(result) {
      this.result = result
      this.turnCameraOff()

      // TODO邏輯
      const self = this
      showNotice('掃描結果 ' + result, function() {
        self.$parent.useVoucher(result)
        self.turnCameraOn()
      })
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    turnCameraOn() {
      this.camera = 'auto'
    },
    turnCameraOff() {
      this.camera = 'off'
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    destroyScanner() {
      this.destroyed = true
    },
    createScanner() {
      this.destroyed = false
    },
    storeCode() {
      this.$parent.toTab('tab3')
    }
  }
}
</script>

<style lang="scss">
    @import "assets/scss/common.scss";

    .camera-area {
        p {
            color: rgba($main-color, $alpha: 1);
            font-size: .85rem;
        }
    }

    #modal_qrcode {
        .modal-content, .modal-dialog {
            height: 100%;
        }

        .modal-content {
            width: 100vw;
        }

        .modal-header {
            border-bottom: none;
        }

        .modal-header .close {
            /*color: #fff;*/
        }
    }

    .btn-next {
        background: #7a63a9;
        color: white;
        line-height: 1;
        padding: 10px;
    }

    .scanner {
        background-color: #efefef;
        margin-bottom: 20px;
        height: 50vh;
    }

    .scanner table {
        text-align: center;
        height: 100%;
        width: 100%;
    }

    .msg {
        margin-bottom: 20px;
    }

    .upload {
        text-align: center;
    }
</style>
