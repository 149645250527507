<template>
  <div class="inter-wrapper">
    <HeaderInter v-if="detail" :back-path="getBackPath" />
    <div class="inter-content">
      <div v-if="detail" class="ticket-detail-area container-fluid">
        <div class="ticket-items">
          <div
            v-if="!detail.validdate_end && !detail.validdate_start"
            class="date"
          >
            使用期限請參考商品內容
          </div>
          <div v-else-if="checkvaliddate(detail.validdate_end)" class="date">
            無期限
          </div>
          <div v-else class="date">
            有效日期 {{ detail.validdate_start.substring(0, 10) }}至{{
              detail.validdate_end.substring(0, 10)
            }}
          </div>
          <div :class="'img ' + getUsageClass" :style="getUsageStyle">
            <div class="circle" />
            <p>{{ getUsageText }}</p>
          </div>
        </div>
        <div class="info-box">
          <div class="main-title">
            {{ detail.item_name }}
          </div>
          <div class="sub-title">
            {{ detail.plan_name }}{{ detail.price_name | pricename }}
          </div>
          <div
            v-if="
              detail.plan.refund_type &&
                detail.plan.refund_type.toString() === '2'
            "
            class="refundtext"
          >
            不可取消
          </div>
        </div>

        <div class="code-area">
          <p class="text-center">
            <small>請選擇兌換方式</small>
          </p>

          <div class="code-tab">
            <tabs
              :tabs="tabs"
              :current-tab="currentTab"
              :wrapper-class="'default-tabs'"
              :tab-class="'default-tabs__item'"
              :tab-active-class="'default-tabs__item_active'"
              :line-class="'default-tabs__active-line'"
              @onClick="handleClick"
            />
            <div class="content">
              <div v-if="currentTab === 'tab1'">
                <div class="text-center px-4 py-3">
                  <!-- 已使用 -->
                  <div
                    v-if="detail.status !== 'READY' && detail.status !== 'DONE'"
                  >
                    <p>{{ getUsageText }}</p>
                  </div>
                  <!-- 純文字, 手動核銷-->
                  <div v-else-if="checkbarcodetype(detail.vendor_voucher_type)">
                    <div v-if="detail.vendor_voucher_value">
                      {{ detail.vendor_voucher_value }}
                    </div>
                    <div v-else>
                      兌換碼異常 請使用其他兌換方式
                    </div>
                    <div
                      v-if="
                        detail.vendor_voucher_value &&
                          detail.self_redeem === 't'
                      "
                      class="btn btn-next mt-2"
                      @click="doVoucherSelf(detail.vendor_voucher_value)"
                    >
                      手動核銷(點擊)
                    </div>
                  </div>
                  <!-- pdf & url -->
                  <div
                    v-else-if="
                      detail.vendor_voucher_type === 'pdf' ||
                        detail.vendor_voucher_type === 'url'
                    "
                  >
                    <img
                      class="pdficon"
                      :src="require('@/assets/images/pdficon.svg')"
                      width="320px"
                      height="100px"
                      @click="toLink(detail.vendor_voucher_value)"
                    >
                  </div>
                  <!-- 圖 + 文字(barcode, qrcode) -->
                  <div v-else-if="detail.vendor_voucher_file">
                    <img
                      :src="detail.vendor_voucher_file"
                      alt=""
                      class="img-fluid"
                    >
                    <div class="ticketcode">
                      票券序號 :
                      {{ showformat_value(detail.vendor_voucher_value) }}
                    </div>
                    <div
                      v-if="detail.self_redeem === 't'"
                      class="btn btn-next mt-2"
                      @click="doVoucherSelf(detail.vendor_voucher_value)"
                    >
                      手動核銷(點擊)
                    </div>
                  </div>
                  <div v-else>
                    暫無條碼 請使用其他兌換方式
                  </div>
                </div>
              </div>
              <div v-if="currentTab === 'tab2'">
                <div class="btn-area text-center px-4 py-3">
                  <button
                    v-if="detail.can_used_scan"
                    class="btn btn-next"
                    type="button"
                    @click="scanQr()"
                  >
                    開啟相機掃描店家QRcode
                  </button>
                  <p v-else>
                    此票券不開放此功能
                  </p>
                  <QrCodeScan />
                </div>
              </div>
              <div v-if="currentTab === 'tab3'">
                <div class="input-area text-center px-4 py-3 d-flex">
                  <input
                    v-model="storecode"
                    type="text"
                    class="box"
                    placeholder="輸入店家兌換碼"
                  >
                  <button
                    class="btn btn-next"
                    type="button"
                    @click="useVoucher()"
                  >
                    送出
                  </button>
                </div>
              </div>
              <!-- <div v-if="currentTab === 'tab4'">
                <div class="text-center px-4 py-3">
                  <p class="mb-2">
                    將票券歸戶至 3TIX 上面販售
                  </p>
                  <button
                    v-if="detail.is_gds_verify"
                    class="btn btn-next"
                    type="button"
                    :disabled="isLoading"
                    @click="handleConsolidation"
                  >
                    歸戶
                  </button>
                </div>
              </div> -->
            </div>
          </div>

          <div class="supplier-logo text-center">
            <img :src="detail.supplier_img" alt="" class="img-fluid">
          </div>
        </div>

        <div class="main">
          <!-- 商品特色 -->
          <dl v-if="detail.plan.description">
            <dt>
              方案說明
            </dt>
            <dd class="nl2br" v-text="detail.plan.description" />
          </dl>
          <dl v-if="detail.plan.guide">
            <dt>使用方法</dt>
            <dd class="nl2br" v-text="detail.plan.guide" />
          </dl>

          <dl v-if="detail.plan.notice">
            <dt>注意事項</dt>
            <dd class="nl2br" v-text="detail.plan.notice" />
          </dl>
          <dl v-if="detail.product.trusttext">
            <dt>信託/價金文字</dt>
            <dd class="nl2br" v-text="detail.product.trusttext" />
          </dl>
          <dl v-if="detail.plan.inclusion">
            <dt>費用包含</dt>
            <dd class="nl2br" v-text="detail.plan.inclusion" />
          </dl>

          <dl v-if="detail.plan.exclusion">
            <dt>費用不包含</dt>
            <dd class="nl2br" v-text="detail.plan.exclusion" />
          </dl>
          <dl v-if="detail.product.issue_info">
            <dt>發行資訊</dt>
            <dd class="nl2br" v-text="detail.product.issue_info" />
          </dl>
          <dl
            v-if="(detail.plan.refund_type && detail.plan.refund_type.toString() === '2') || detail.plan.policy_cancel"
          >
            <dt>取消政策</dt>
            <dd class="nl2br">
              {{ detail.plan.policy_cancel || '不可取消' }}
            </dd>
          </dl>
          <dl>
            <dt>店家資訊</dt>
            <dd v-for="(item, index) in detail.plan.branches" :key="index">
              {{ item.area }} {{ item.branch_name }} {{ item.branch_address }}
              {{ item.branch_phone }}
            </dd>
          </dl>
        </div>
      </div>
      <b-modal
        id="modal-code"
        title="身份驗證"
        :hide-footer="true"
        ok-only
        no-stacking
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
      >
        <div class="form-area">
          <p>
            <small>訂單編號:{{ $route.params.num }}</small>
          </p>
          <input
            v-model="codeValidate.phone"
            v-validate="'required'"
            type="tel"
            class="box"
            placeholder="手機"
            name="phone"
            data-vv-as="手機"
            data-vv-scope="codeValidate"
          >
          <div v-if="errors.has('codeValidate.phone')" class="help-block">
            {{ errors.first('codeValidate.phone') }}
          </div>

          <button class="btn btn-next" type="button" @click="codeSubmit()">
            確認送出
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import Tabs from 'vue-tabs-with-active-line'
import { getVoucher, useVoucher, useVoucherSelf, getGdsVerify } from '@/api/voucher'
import { userLogin } from '@/utils/user'
import { showNotice, showConfirm, getENVtype } from '@/utils/tool'
import QrCodeScan from '@/components/QrCodeScan'

export default {
  name: 'VoucherDetail',
  components: {
    HeaderInter,
    Tabs,
    QrCodeScan
  },
  data() {
    return {
      backPath: null,
      currentTab: 'tab1',
      detail: false,
      codeValidate: {
        phone: null,
        code: null
      },
      storecode: null,
      isLoading: false
    }
  },
  computed: {
    tabs() {
      let tempTabs = [
        {
          title: '出示條碼',
          value: 'tab1'
        }
      ]

      if (this.detail.status === 'DONE' && this.detail.can_used_scan) {
        tempTabs = tempTabs.concat([
          {
            title: '掃描商家號碼',
            value: 'tab2'
          },
          {
            title: '輸入門市代碼',
            value: 'tab3'
          }
        ])
      } else {
        tempTabs = tempTabs.concat([
          {
            title: '掃描商家號碼',
            value: 'tab2',
            disabled: true
          },
          {
            title: '輸入門市代碼',
            value: 'tab3',
            disabled: true
          }
        ])
      }

      // 檢查是否可歸戶至次級市場
      // if (this.detail.status === 'DONE' && this.detail.is_gds_verify) {
      //   tempTabs = tempTabs.concat([
      //     {
      //       title: '票券歸戶',
      //       value: 'tab4'
      //     }
      //   ])
      // }

      return tempTabs
    },
    getUsageClass() {
      // REDEEM已使用 READY 準備中 DONE 已取得憑証 CANCEL 作廢
      switch (this.detail.status) {
        case 'REDEEM':
          return 'used'
        case 'CANCEL':
          return 'void'
        default:
          return ''
      }
    },
    getUsageStyle() {
      if (this.detail.status !== 'REDEEM' && this.detail.status !== 'CANCEL') {
        return (
          'background-image: url(' +
          require('@/assets/images/icon-ufo-w.svg') +
          '), linear-gradient( 90deg, rgba(84, 84, 84, 0) 80%, rgb(84, 84, 84) 80%), url(' +
          this.detail.product.cover_image +
          ');'
        )
      } else {
        return ''
      }
    },
    getUsageText() {
      // REDEEM已使用 READY 準備中 DONE 已取得憑証 CANCEL 作廢
      switch (this.detail.status) {
        case 'REDEEM':
          return '票券憑證已使用'
        case 'CANCEL':
          return '票券憑證已作廢'
        default:
          return ''
      }
    },
    getBackPath() {
      let mode = 'non-used'
      let _cate = ''
      switch (this.detail.status) {
        case 'REDEEM':
          mode = 'used'
          break
        case 'CANCEL':
          mode = 'expired'
          break
        default:
          mode = 'non-used'
          break
      }
      if (
        this.$route.params.cate != null ||
        this.$route.params.cate !== undefined
      ) {
        _cate = '/' + this.$route.params.cate
      }
      return '/voucher/mode/' + mode + _cate
    }
  },
  created() {
    // getVoucher(this.$route.params.num).then(response => {
    //   this.detail = response.data.data
    // })
  },
  mounted() {
    const host = new URL(window.location.href || document.location.href)
    const code = host.searchParams.get('code')
    const self = this
    if (
      this.$store.getters.authInfo.token &&
      this.$store.getters.authInfo.third_verify
    ) {
      getVoucher(this.$route.params.num, {}).then(response => {
        if (response.data.succ) {
          this.detail = response.data.data
        } else {
          self.$router.push('/voucher')
        }
      })
    } else {
      if (code) {
        // 用code取資訊
        this.codeValidate.code = code
        this.showModal('modal-code')
      } else {
        // 未登入
        showNotice('請進行登入', function() {
          userLogin(self.$router.currentRoute)
        })
      }
    }
  },
  methods: {
    checkvaliddate(date) {
      if (date && new Date(date).getFullYear() === 2099) {
        return true
      } else return false
    },
    checkbarcodetype(type) {
      // type = text 其他false
      const isbarcode39 = /^barcode39/gm
      const isbarcode128 = /^barcode128/gm
      if (
        isbarcode39.test(type) ||
        isbarcode128.test(type) ||
        type === 'qrcode' ||
        type === 'pdf' ||
        type === 'url'
      ) {
        return false
      } else return true
    },
    showformat_value(value) {
      const text = value.split('@')
      if (text.length) {
        let finallytext = ''
        text.forEach((element, i) => {
          if (i > 0) {
            finallytext += ' \n票券序號 : ' + element
          } else {
            finallytext += element
          }
        })
        return finallytext
      } else {
        return value
      }
    },
    handleClick(newTab) {
      this.currentTab = newTab
    },
    codeSubmit() {
      const self = this
      this.$validator.validateAll('codeValidate').then(valid => {
        if (valid) {
          getVoucher(self.$route.params.num, this.codeValidate).then(
            response => {
              if (!response.data.succ) {
                showNotice('資料驗證失敗', function() {
                  self.showModal('modal-code')
                })
              } else {
                self.backPath = '/voucher'
                self.detail = response.data.data
                self.hideModal('modal-code')
              }
            }
          )
        }
      })
    },
    showModal(id) {
      this.$bvModal.show(id)
    },
    hideModal(id) {
      this.$bvModal.hide(id)
    },
    scanQr() {
      this.$bvModal.show('modal_qrcode')
    },
    toTab(index) {
      this.currentTab = index
    },
    doVoucherSelf(res_code) {
      const self = this
      showConfirm(
        '核銷序號：' + res_code,
        async function() {
          try {
            let data = {}
            if (
              !self.$store.getters.authInfo.token &&
              !self.$store.getters.authInfo.third_verify
            ) {
              data = self.codeValidate
            }
            const res = await useVoucherSelf(self.detail.voucher_num, data)
            if (res.data.succ) {
              showNotice('兌換成功', function() {
                getVoucher(self.$route.params.num, data).then(response => {
                  if (response.data.succ) {
                    self.detail = response.data.data
                    self.currentTab = 'tab1'
                  } else {
                    self.$router.push('/voucher')
                  }
                })
              })
            } else {
              if (res.data.msg) {
                showNotice(`手動核銷失敗，${res.data.msg}`)
              } else {
                showNotice('手動核銷失敗，請重新嘗試並保持您的網路環境穩定。')
              }
            }
          } catch (error) {
            console.log(error)
          }
        },
        function() {
          // cancel do nothing
        },
        '<span><span class="warning">請確認票券序號已使用完畢再核銷 !</span>\n\n點選【核銷】後即完成核銷(票券即失效)，是否確認要核銷此序號？' +
          '\n<span class="warning">\n* 手動核銷前：</span>\n' +
          '請確認憑證序號已完成兌換，再進行手動核銷，憑證序號一經核銷即失效，無法再使用！\n' +
          '兌換方式:\n' +
          '1.若憑證為「純序號」，請依商品頁的使用方法兌換\n' +
          '2.若憑證為「條碼」，請出示給店家掃描兌換' +
          '</span>',
        ['核銷(點擊後失效)', '取消']
      )
    },
    useVoucher(res_code) {
      const _storecode = res_code || this.storecode
      const data = { store_code: _storecode }
      const self = this
      let reqdata = {}
      if (
        !this.$store.getters.authInfo.token &&
        !this.$store.getters.authInfo.third_verify
      ) {
        reqdata = this.codeValidate
        data.code = this.codeValidate.code
        data.phone = self.codeValidate.phone
      }
      useVoucher(self.detail.voucher_num, data).then(response => {
        if (!response.data.succ) {
          showNotice('兌換失敗')
        } else {
          showNotice('兌換成功', function() {
            getVoucher(self.$route.params.num, reqdata).then(response => {
              if (response.data.succ) {
                self.detail = response.data.data
                self.currentTab = 'tab1'
              } else {
                self.$router.push('/voucher')
              }
            })
          })
        }
      })
    },
    toLink(link) {
      if (link) window.open(link, '_blank')
      else showNotice('憑證不存在, 請聯絡客服人員!')
    },
    handleConsolidation() {
      const self = this
      // 彈窗：確認是否執行歸戶
      showConfirm(
        '點擊確認後執行歸戶票券',
        async() => {
          try {
            self.isLoading = true
            const { data } = await getGdsVerify(self.detail.voucher_num)
            self.isLoading = false
            const { verification_token } = data.data
            const env = getENVtype()

            if (env === 'dev') {
              // window.location.href = `http://localhost:5173/redirect?ticket=${verification_token}`
              window.location.href = `https://dev.3tix.cc/redirect?ticket=${verification_token}`
            } else if (env === 'qa') {
              window.location.href = `https://qa.3tix.cc/redirect?ticket=${verification_token}`
            } else {
              window.location.href = `https://3tix.cc/redirect?ticket=${verification_token}`
            }
          } catch (error) {
            self.isLoading = false
            // console.log(error)
          }
        },
        () => {},
        '',
        ['確認', '取消']
      )
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/common.scss';
.ticket-detail-area {
  .ticket-items {
    position: relative;
    z-index: 1;
    padding: 0px;
    .date {
      text-align: center;
      font-size: 1rem;
      color: rgba($main-color, $alpha: 1);
      padding: 3px;
      margin-bottom: 5px;
    }
    .img {
      background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
          calc(100% - 7px) calc(100% - 0px),
        linear-gradient(
          90deg,
          rgba($sec-color, $alpha: 0) 80%,
          rgba($sec-color, $alpha: 1) 80%
        ),
        url(~@/assets/images/2.jpg) no-repeat center center;
      background-size: 45px, auto, cover;
      // background-size: 55px, auto, cover;
      // -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      // mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      max-width: 80%;
      margin: 0 auto;
      height: 180px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: relative;

      .circle {
        width: 20px;
        height: 20px;
        background: rgba($white-color, $alpha: 1);
        position: absolute;
        border-radius: 50%;
        top: calc(50% - 10px);
        right: -10px;
      }
      p {
        color: rgba($white-color, $alpha: 0.5);
      }
    }

    .img.void {
      background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
          calc(100% - 7px) calc(100% - 0px),
        url(~@/assets/images/icon-sold-a.svg) no-repeat -5px 5px,
        linear-gradient(
          90deg,
          rgba($sec-color, $alpha: 1) 80%,
          rgba($sec-color, $alpha: 1) 80%
        ),
        url(~@/assets/images/2.jpg) no-repeat center center;
      background-size: 45px, 80px, auto;
      // background-size: 55px, 80px, auto, cover;
      // -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      // mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      max-width: 80%;
      height: 180px;
      margin: 0 auto;
    }

    .img.used {
      background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
          calc(100% - 7px) calc(100% - 0px),
        url(~@/assets/images/icon-sold-b.svg) no-repeat -5px 5px,
        linear-gradient(
          90deg,
          rgba($sec-color, $alpha: 1) 80%,
          rgba($sec-color, $alpha: 1) 80%
        ),
        url(~@/assets/images/2.jpg) no-repeat center center;
      background-size: 45px, 80px, auto;
      // -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      // mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
      max-width: 80%;
      height: 180px;
      margin: 0 auto;
    }
  }
  .info-box {
    color: rgba($sec-color, $alpha: 1);
    text-align: center;
    padding: 6px 0px 6px;
    z-index: 9;
    .main-title {
      font-size: 1rem;
    }
    .sub-title {
      font-size: 0.85rem;
    }
  }
  .main {
    border-top: 1px solid rgba($sec-color, $alpha: 0.25);
    border-bottom: 1px solid rgba($sec-color, $alpha: 0.25);
    padding-top: 2rem;
    p {
      margin: 0 0 0.5rem;
      padding: 0;
    }
    dl {
      padding-bottom: 1rem;
    }
    dt {
      color: $main-color;
      margin-bottom: 0.35rem;
    }
    dd {
      font-size: 1rem;
      line-height: 1.85rem;
      margin: 0;
      padding: 0;
    }
    dd span {
      color: $sec-color;
    }
    ol,
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      line-height: 1.85rem;
      .price {
        color: $notice-color;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .ticket-detail-area {
    .ticket-items {
      .img {
        background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
            calc(100% - 145px) calc(100% - 3px),
          linear-gradient(
            90deg,
            rgba($sec-color, $alpha: 0) 64%,
            rgba($sec-color, $alpha: 1) 64%
          ),
          url(~@/assets/images/2.jpg) no-repeat center center;
        background-size: 55px, auto, cover;
        -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center
          bottom !important;
        mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
        max-width: 80%;
        height: 200px;
      }
      .img.void {
        background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
            calc(100% - 145px) calc(100% - 3px),
          url(~@/assets/images/icon-sold-a.svg) no-repeat 140px 5px,
          linear-gradient(
            90deg,
            rgba($sec-color, $alpha: 1) 80%,
            rgba($sec-color, $alpha: 1) 80%
          ),
          url(~@/assets/images/2.jpg) no-repeat center center;
        background-size: 55px, 100px, auto, cover;
        -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center
          bottom !important;
        mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
        max-width: 80%;
        height: 200px;
      }
      .img.used {
        background: url(~@/assets/images/icon-ufo-w.svg) no-repeat
            calc(100% - 145px) calc(100% - 3px),
          url(~@/assets/images/icon-sold-b.svg) no-repeat 140px 5px,
          linear-gradient(
            90deg,
            rgba($sec-color, $alpha: 1) 80%,
            rgba($sec-color, $alpha: 1) 80%
          ),
          url(~@/assets/images/2.jpg) no-repeat center center;
        background-size: 55px, 100px, auto, cover;
        -webkit-mask: url(~@/assets/images/svg-ticket.svg) no-repeat center
          bottom !important;
        mask: url(~@/assets/images/svg-ticket.svg) no-repeat center bottom !important;
        max-width: 80%;
        height: 200px;
      }
    }
  }
}

//codeTab
.code-area {
  padding-bottom: 1rem;
  p {
    padding: 5px 0;
    margin: 0;
  }
  .btn-area {
    margin: 0 auto 1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    .btn {
      flex: 0 0 80%;
      border-radius: 0;
      padding: 10px;
    }
    .btn-next {
      background: $main-color;
      color: $white-color;
    }
  }
  .default-tabs {
    position: relative;
    margin: 5px auto 8px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__item {
      font-size: 0.9rem;
      color: $font-color;
      text-decoration: none;
      border: none;
      background-color: $white-color;
      border-bottom: 2px solid rgba($sec-color, $alpha: 0.25);
      cursor: pointer;
      transition: all 0.25s;
      .pdficon {
        cursor: pointer;
      }
      &_active {
        border-bottom: 2px solid rgba($sec-color, $alpha: 0.25);
        color: $main-color;
      }
      &:hover {
        border-bottom: 2px solid rgba($sec-color, $alpha: 0.25);
        color: $main-color;
      }
      &:focus {
        outline: none;
        border-bottom: 2px solid rgba($sec-color, $alpha: 0.25);
        color: black;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        color: #ccc !important;
      }
    }
    &__active-line {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: rgba($main-color, $alpha: 1);
      transition: transform 0.4s ease, width 0.4s ease;
    }
  }
  .ticketcode {
    white-space: pre-line;
    word-break: break-all;
    text-align: center;
    color: red;
    font-weight: 600;
  }
}
.supplier-logo {
  width: 100px;
  margin: 1rem auto 0;
}
.input-area {
  .btn {
    flex: 0 0 auto;
    border-radius: 0;
    font-size: 0.85rem;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 0;
  }
  .btn-next {
    background: $main-color;
    color: $white-color;
  }
}
.form-area {
  display: flex;
  flex-direction: column;
  .btn {
    flex: 0 0 100%;
    font-size: 0.85rem;
    border-radius: 0;
    padding: 10px;
  }
  .btn-next {
    background: rgba($main-color, $alpha: 1);
    color: $white-color;
  }
}

.refundtext {
  display: inline;
  line-height: 1;
  background-color: $price-color;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 8px;
  white-space: nowrap;
}
</style>
